export const LOGIN_API = '/api/v1/auth/users/sign_in'
export const LOGIN_CHUB_API = '/careerhub/api/v1/auth/career_hub_users/sign_in'
export const LOGIN_SSO_API = '/api/v1/auth/users/sso'
export const LOGOUT_API = '/api/v1/auth/users/sign_out'
export const INVITATIONS_CONFIRM_API = '/api/v1/auth/invitations/confirm'
export const DOWNLOAD_FILE_API = '/api/v1/attachments/download'
export const SIGN_OUT_API = '/api/auth/sign-out'
export const VERIFY_RECAPTCHA_API = '/api/verify-recaptcha'
export const VERIFY_LOGIN_MICROSOFT_API = '/api/verify-login-microsoft'
export const GRAPHQL_API = '/graphql'
export const AGENCY_API = '/agency'
export const CHUB_API = '/careerhub'
export const AGENCY_INVITATIONS_CONFIRM_API =
  '/agency/api/v1/auth/invitations/confirm'
