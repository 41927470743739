import {
  AGENCY_API,
  AGENCY_INVITATIONS_CONFIRM_API,
  DOWNLOAD_FILE_API,
  GRAPHQL_API,
  INVITATIONS_CONFIRM_API,
  LOGIN_API,
  LOGIN_SSO_API,
  LOGOUT_API,
  SIGN_OUT_API,
  VERIFY_RECAPTCHA_API,
  CHUB_API,
  LOGIN_CHUB_API
} from '../core/constants/api'
import { PUBLIC_API_URL, PUBLIC_ENABLE_API_PROXY } from '../core/constants/env'

const apiConfiguration = {
  domain: PUBLIC_API_URL,
  login: `${PUBLIC_API_URL}${LOGIN_API}`,
  CHUBlogin: `${PUBLIC_API_URL}${LOGIN_CHUB_API}`,
  loginSSO: `${PUBLIC_API_URL}${LOGIN_SSO_API}`,
  logout: `${PUBLIC_API_URL}${LOGOUT_API}`,
  invitationsConfirm: `${PUBLIC_API_URL}${INVITATIONS_CONFIRM_API}`,
  signOut: SIGN_OUT_API,
  verifyReCaptcha: VERIFY_RECAPTCHA_API,
  downloadFile: `${PUBLIC_API_URL}${DOWNLOAD_FILE_API}`,
  graphQL: `${PUBLIC_ENABLE_API_PROXY ? '' : PUBLIC_API_URL}${GRAPHQL_API}`,
  agencyGraphQL: `${
    PUBLIC_ENABLE_API_PROXY ? '' : PUBLIC_API_URL
  }${AGENCY_API}${GRAPHQL_API}`,
  chubGraphQL: `${
    PUBLIC_ENABLE_API_PROXY ? '' : PUBLIC_API_URL
  }${CHUB_API}${GRAPHQL_API}`,
  agencyInvitationsConfirm: `${PUBLIC_API_URL}${AGENCY_INVITATIONS_CONFIRM_API}`
}

export default apiConfiguration
